<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue'

import InfoIcon from '@lahaus-roomie/roomie/src/assets/icons/info.svg'
import UserIcon from '@lahaus-roomie/roomie/src/assets/icons/user.svg'
import RoomieOverlay from '@lahaus-roomie/roomie/src/components/RoomieOverlay/index.vue'
import RoomieDivider from '@lahaus-roomie/roomie/src/components/RoomieDivider/index.vue'

import type { Props } from './types'
import NavBar from '@/components/NavBar/index.vue'

import { useUserStore } from '@/stores/userStore'

import './_index.scss'

const { userLabel, isAuthenticated } = useUserStore()

defineProps<Props>()

const emit = defineEmits(['on-side-menu-click'])

const stringParameterize = (str: string) => str ? str.trim().toLowerCase().replace(/\s/g, '-').replace(/\//, '') : ''

const animationClass = ref('')
const opacity = ref('opacity-100')

const onSideMenuClick = () => {
  animationClass.value = 'slide-out'
  opacity.value = 'opacity-0'

  setTimeout(() => {
    emit('on-side-menu-click')
  }, 750)
}

const disableScroll = () => {
  document.body.classList.add('stop-scrolling')
}

const enableScroll = () => {
  document.body.classList.remove('stop-scrolling')
}

onMounted(() => {
  disableScroll()
})

onUnmounted(() => {
  enableScroll()
})

</script>

<script lang="ts">
export default {
  name: 'SideMenuMobile'
}
</script>

<template>
  <RoomieOverlay
    class="side-menu transition-opacity duration-700 delay-300 ease-out"
    :class="opacity">
    <div
      class="side-menu__holder"
      :class="animationClass">
      <NavBar
        :items="items"
        :is-in-side-menu="true"
        @on-side-menu-click="onSideMenuClick" />

      <div class="side-menu__content text-16">
        <ul class="flex-row justify-end mt-24">
          <li
            v-for="(item, index) in items"
            :key="index">
            <a
              v-href="item.path"
              :data-lh-id="`navbar-item-${stringParameterize(item.path)}`"
              :target="item.target ? '_blank' : '_self'"
              class="global-navbar__item-mobile"
              :class="item.class"
              :aria-label=" item.label"
              rel="nofollow">
              <div class="flex items-center">
                {{ item.label }}
                <span
                  v-if="item.id === 'help-center'"
                  class="flex items-center justify-center">

                  <InfoIcon class="ml-4 w-20 h-20 fill-carbon-800 pointer-events-none" />
                </span>
              </div>
            </a>
          </li>

          <div class="my-24">
            <RoomieDivider
              color="carbon-light-300"
              :rounded="false"
              :vertical="false" />
          </div>

          <li
            v-if="isAuthenticated"
            class="global-navbar__item-mobile">
            <span
              data-lh-id="navbar-item-user-label"
              class="flex justify-start"
              rel="nofollow">
              <div
                class="flex items-center justify-center ml-4 -mt-4">
                <UserIcon class="global-navbar__icon w-24 h-24 fill-carbon-800 pointer-events-none" />
              </div>

              {{ userLabel }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </RoomieOverlay>
</template>
