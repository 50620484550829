<script lang="ts" setup>
import { computed } from 'vue'

import InfoIcon from '@lahaus-roomie/roomie/src/assets/icons/info.svg'
import CloseIcon from '@lahaus-roomie/roomie/src/assets/icons/close.svg'
import MenuLargeIcon from '@lahaus-roomie/roomie/src/assets/icons/menu-large.svg'
import UserIcon from '@lahaus-roomie/roomie/src/assets/icons/user.svg'
import type { Props } from './types'
import { useUserStore } from '@/stores/userStore'
import './_index.scss'

const userStore = useUserStore()

const { userLabel, isAuthenticated } = userStore

const props = withDefaults(defineProps<Props>(), {
  isInSideMenu: false
})

const emit = defineEmits(['on-side-menu-click'])

const stringParameterize = (str: string) => str ? str.trim().toLowerCase().replace(/\s/g, '-').replace(/\//, '') : ''

const sideMenuIcon = computed(() => {
  return props.isInSideMenu ? 'close' : 'menu-large'
})

const menuBtnExtraClass = computed(() => {
  return props.isInSideMenu ? '' : 'lg:hidden'
})

const handleSideMenuClick = () => {
  emit('on-side-menu-click')
}

</script>

<script lang="ts">
export default {
  name: 'NavBar'
}
</script>

<template>
  <nav class="border-b border-carbon-light-50 relative bg-white">
    <div class="flex flex-row lg:container items-center justify-between global-navbar px-24 mx-auto">
      <a
        href="/"
        aria-label="Logo La Haus">
        <img
          class="global-navbar__logo-img pointer-events-none"
          src="https://lahaus.imgix.net/static/branding/logo-lahaus-full.svg?auto=compress,format&dpr=2"
          width="125"
          height="24"
          alt="La Haus Logo"
          loading="lazy">
      </a>

      <ul
        class="global-navbar__items-holder mt-20 hidden flex-row justify-end lg:flex">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="global-navbar__item ml-20 pb-20 text-14 border-b-2 border-transparent">
          <a
            v-href="item.path"
            :data-lh-id="`navbar-item-${stringParameterize(item.path)}`"
            :target="item.target ? '_blank' : '_self'"
            class="pb-20 pt-20"
            :class="item.class"
            :aria-label=" item.label"
            rel="nofollow">
            <div class="flex items-center">
              {{ item.label }}
              <span
                v-if="item.id === 'help-center'"
                class="flex items-center justify-center">

                <InfoIcon class="ml-4 w-20 h-20 fill-carbon-800 pointer-events-none" />
              </span>
            </div>
          </a>
        </li>

        <li
          v-if="isAuthenticated"
          class="global-navbar__item ml-20 pb-20 text-14 border-b-2 border-transparent">
          <span
            data-lh-id="navbar-item-user-label"
            class="flex justify-start"
            rel="nofollow">
            {{ userLabel }}

            <div class="flex items-center justify-center ml-4 -mt-4">
              <UserIcon class="global-navbar__icon w-24 h-24 fill-carbon-800 pointer-events-none" />
            </div>
          </span>
        </li>
      </ul>

      <div
        class="global-navbar__menu w-60 h-60 cursor-pointer flex items-center justify-end"
        :class="menuBtnExtraClass"
        @click="handleSideMenuClick">
        <span class="w-32 h-32">
          <CloseIcon
            v-if="sideMenuIcon === 'close'"
            class="w-32 h-32" />

          <MenuLargeIcon
            v-else
            class="w-32 h-32" />
        </span>
      </div>
    </div>
  </nav>
</template>
